import './Footer.css';

const Footer = () => {
  return (
    <div className="footer">
        <p>© FINITY 2024</p>
    </div>
  );
};

export default Footer;