import React, { createContext, useContext, useState } from "react";

const ContactContext = createContext(undefined);

export const useContact = () => {
  const context = useContext(ContactContext);
    return context;
};

export const ContactProvider = ({ children }) => {
  const [currentContactVisibility, setContactVisibility] = useState(false);

  return (
    <ContactContext.Provider value={{ currentContactVisibility, setContactVisibility }}>
      {children}
    </ContactContext.Provider>
  );
};