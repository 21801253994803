export class Particle {
  constructor(
    particleNum,
    particleSpeed,
    particlePresets,
    posArray,
    pointer,
    resolution
  ) {
    this.pos = posArray.subarray(particleNum * 3, particleNum * 3 + 3);
    this.pointer = pointer;
    this.runned = 0;
    this.particleNum = particleNum;
    this.speed = particleSpeed;
    this.particlePresets = particlePresets;
    this.resolution = resolution;
  }

  move(particlePositions) {
    this.particlePositions = particlePositions;
    if (this.runned === 0) {
      this.runned = 1;
      this.x = this.resolution.nativeWidth - Math.floor(Math.random() * this.resolution.nativeWidth);
      this.y = this.resolution.nativeHeight + Math.floor(Math.random() * this.resolution.nativeHeight * 2);
    } else {
      for (const [pos, fn] of this.particlePresets) {
        if (pos === this.particlePositions) {
          fn(this);
          break;
        }
      }
    }
  }
}
