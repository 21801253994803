import React, { createContext, useContext, useState } from "react";

const ParticleContext = createContext(undefined);

export const useParticle = () => {
  const context = useContext(ParticleContext);
    return context;
};

export const ParticleProvider = ({ children }) => {
  const [currentParticleIndex, setParticleIndex] = useState(0);

  return (
    <ParticleContext.Provider value={{ currentParticleIndex, setParticleIndex }}>
      {children}
    </ParticleContext.Provider>
  );
};