import './Header.css';
import React, { useState, useEffect, useRef } from 'react';

import { useParticle } from '../webgl/ParticleContext';
import { useContact } from '../ContactContext/ContactContext';

const Header = () => {
  const { setParticleIndex } = useParticle();
  const { setContactVisibility } = useContact();

  const menuIconRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    if (menuIconRef.current) {
      document.documentElement.style.overflowY = isMenuOpen ? 'visible' : 'hidden';

      menuIconRef.current.classList.toggle("openIcon", !isMenuOpen);
      menuIconRef.current.classList.toggle("blackIcon", !isMenuOpen);

	  if(!isMenuOpen){
		setParticleIndex(14);
      	setContactVisibility(true);
	  }
	  else {
		setParticleIndex(0);
      	setContactVisibility(false);
	  }

      setIsMenuOpen((prevState) => !prevState);
    }
  };

  useEffect(() => {

  }, []);

  return (
    <div className="header">
		<div className={"icon-wrapper"}>
			<div ref={menuIconRef} className="menuIcon" onClick={toggleMenu}>
				<span></span>
				<span></span>
			</div>
		</div>
    </div>
  );
};

export default Header;