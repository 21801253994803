import "./Contact.css"
import { useRef, useEffect } from "react"

import { useContact } from '../ContactContext/ContactContext';

const Contact = () => {
  const contactDiv = useRef(null);
  const divRef = useRef(null);

  const { currentContactVisibility } = useContact();

  const animate = () => {
    divRef.current.classList.add("kiss");
    setTimeout(() => divRef.current.classList.remove("kiss"), 200);
  }

  useEffect(() => {
    if (currentContactVisibility) {
      contactDiv.current.classList.remove("unvisible");
    } else {
      contactDiv.current.classList.add("unvisible");
    }
  }, [currentContactVisibility]);

  return (
    <div className={`contact ${currentContactVisibility ? '' : 'unvisible'}`} ref={contactDiv}>
      <div className="container">
        <div className="content">
          <div className="working-hours">
            <h1>Monday to Friday</h1>
            <p>08:00 - 16:00</p>
          </div>
          <div ref={divRef} className="email" onClick={() => {animate()}}>
            <a href="mailto:office@finitysoftware.io">office@finitysoftware.io</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
