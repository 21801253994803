const gridMatrix = [];

let num = 0;
for (let index = 0; index < 150; index++) {
  for (let index2 = 0; index2 < 150; index2++) {
    gridMatrix[num] = [];
    gridMatrix[num][0] = index;
    gridMatrix[num][1] = index2;
    num++;
  }
}

const shuffle = function(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};

shuffle(gridMatrix);

export const particles_grid = function(particle) {
  const dx = particle.pointer.x - particle.x;
  const dy = particle.pointer.y - particle.y;
  const d = Math.sqrt(dx * dx + dy * dy) / particle.speed / 10;
  const s = 20 / d;
  let speed0 = particle.speed;
  if (particle.speed <= 20) {
    speed0 = 20;
  }

  // Interpolation factor (value between 0 and 1)
  const interpolationFactor = 0.05; // Adjust this value for faster or slower interpolation

  // Target position based on grid
  const targetX = gridMatrix[particle.particleNum][0] * (particle.resolution.nativeWidth / 125);
  const targetY = gridMatrix[particle.particleNum][1] * (particle.resolution.nativeHeight / 100);

  // Interpolate particle's position
  particle.x += (targetX - particle.x) * interpolationFactor - s * (dx / d);
  particle.y += (targetY - particle.y) * interpolationFactor - s * (dy / d);

  // Update buffer position
  particle.pos[0] = particle.x;
  particle.pos[1] = particle.y;
  particle.pos[2] = 0.05 * s * s;
};
