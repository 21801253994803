import { logo } from "./aktido.js";

const shuffle = function(array) {
  array.sort(() => Math.random() - 0.5);
};

shuffle(logo);

export const aktido_desktop = function(particle) {
  const currentNum = particle.particleNum % logo.length;
  const logox = (logo[currentNum][0] * particle.resolution.width) / (3 / particle.resolution.ratio) + particle.resolution.nativeWidth / 2.02 + Math.round(Math.random() * 20);
  const logoy = (logo[currentNum][1] * particle.resolution.width) / (3 / particle.resolution.ratio) + particle.resolution.nativeHeight / 2.0 + Math.round(Math.random() * 20);
  const dx = particle.pointer.x - particle.x;
  const dy = particle.pointer.y - particle.y;
  const d = Math.sqrt(dx * dx + dy * dy);
  const s = 10 / d;
  particle.x += -s * (dx / d) + ((logox - particle.x) * particle.speed) / 1000;
  particle.y += -s * (dy / d) + ((logoy - particle.y) * particle.speed) / 1000;
  particle.pos[0] = particle.x;
  particle.pos[1] = particle.y;
  particle.pos[2] = (particle.speed / 40) * s * s;
};
