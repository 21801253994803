import React, { useEffect, useRef, useState } from 'react';
import { useParticle } from '../webgl/ParticleContext';
import './TapHere.css';

const TapHere = () => {
  const tapHereRef = useRef(null);
  const textRef = useRef(null);

  const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

  const [text, setText] = useState(isMobile ? "TAP HERE TO SEE CLIENTS" : "CLICK HERE TO SEE OUR CLIENTS");

  const { setParticleIndex, currentParticleIndex } = useParticle();

  const animate = () => {
    textRef.current.classList.add("kiss");
    setTimeout(() => textRef.current.classList.remove("kiss"), 200);
  }

  const changeText = (index) => {
    if(index === 0)
      setText(isMobile ? "TAP HERE TO SEE CLIENTS" : "CLICK HERE TO SEE OUR CLIENTS");
    else if(index > 0)
      setText(isMobile ? "TAP MORE" : "CLICK MORE");
  }

  const handleClick = () => {
    animate();
    let index = currentParticleIndex + 1;
    if (index === 14) index = 0;
    setParticleIndex(index);
    changeText(index);
  };

  useEffect(() => {
    if (tapHereRef.current) {
      tapHereRef.current.style.display = currentParticleIndex === 14 ? 'none' : 'block';
    }
  }, [currentParticleIndex]);

  return (
    <div ref={tapHereRef} className="tapHereContainer" onClick={handleClick}>
      <p ref={textRef} className="tapHereText animate-flicker">{text}</p>
    </div>
  );
};

export default TapHere;