import './App.css';

import React, { useState } from 'react';

import Header from './Header/Header';
import WebGLParticles from './webgl/WebGLParticles';
import { ParticleProvider } from './webgl/ParticleContext';
import TapHere from './TapHere/TapHere';
import Footer from './Footer/Footer';
import { ContactProvider } from './ContactContext/ContactContext';
import Contact from './Contact/Contact';

function App() {
  return (
    <div className="App">
      <ParticleProvider>
        <ContactProvider>
          <Header/>
          <WebGLParticles />
          <Contact />
          <TapHere />
          <Footer />
        </ContactProvider>
      </ParticleProvider>
    </div>
  );
}

export default App;
