import React, { useEffect, useRef } from 'react';
import { finity_mobile} from "./particles/finity/finity-mobile.js";
import { particles_aktido } from "./particles/aktido/particles-aktido.js";
import { particles_tresor } from "./particles/tresor/particles-tresor.js";
import { particles_tresorevents } from "./particles/tresorevents/particles-tresorevents.js";
import { particles_dizgram } from "./particles/dizgram/particles-dizgram.js";
import { particles_mkautomobile } from "./particles/mkautomobile/particles-mkautomobile.js";
import { particles_lukafotograf } from "./particles/lukafotograf/particles-lukafotograf.js";
import { particles_mtel } from "./particles/mtel/particles-mtel.js";
import { particles_markovarealestate } from "./particles/markovarealestate/particles-markovarealestate.js";
import { particles_eventhallvienna } from "./particles/eventhallvienna/particles-eventhallvienna.js";
import { particles_gogreen } from "./particles/gogreen/particles-gogreen.js";
import { particles_gronbil } from "./particles/gronbil/particles-gronbil.js";
import { particles_inzinjering } from "./particles/inzinjering/particles-inzinjering.js";
import { particles_cineart } from "./particles/cineart/particles-cineart.js";


import { finity_desktop } from "./particles/finity/finity-desktop.js";
import { aktido_desktop } from "./particles/aktido/aktido-desktop.js";
import { tresor_desktop } from "./particles/tresor/tresor-desktop.js";
import { tresorevents_desktop } from "./particles/tresorevents/tresorevents-desktop.js";
import { dizgram_desktop } from "./particles/dizgram/dizgram-desktop.js";
import { mkautomobile_desktop } from "./particles/mkautomobile/mkautomobile-desktop.js";
import { lukafotograf_desktop } from "./particles/lukafotograf/lukafotograf-desktop.js";
import { mtel_desktop } from "./particles/mtel/mtel-desktop.js";
import { markovarealestate_desktop } from "./particles/markovarealestate/markovarealestate-desktop.js";
import { eventhallvienna_desktop } from "./particles/eventhallvienna/eventhallvienna-desktop.js";
import { gogreen_desktop } from "./particles/gogreen/gogreen-desktop.js";
import { gronbil_desktop } from "./particles/gronbil/gronbil-desktop.js";
import { inzinjering_desktop } from "./particles/inzinjering/inzinjering-desktop.js";
import { cineart_desktop } from "./particles/cineart/cineart-desktop.js";

import { particles_grid } from "./particles/grid/particles-grid.js";
import { Particle } from "./Particle.js";

import { useParticle } from './ParticleContext';

const WebGLParticles = () => {
    const { currentParticleIndex } = useParticle();

    const currentParticleIndexRef = useRef(currentParticleIndex);

    const particles = useRef([]);
    const canvasRef = useRef(null);

    const resolutionRef = useRef({
      ratio: window.devicePixelRatio || 1,
      nativeWidth: window.innerWidth * (window.devicePixelRatio || 1),
      nativeHeight: window.innerHeight * (window.devicePixelRatio || 1),
      width: window.innerWidth,
      height: window.innerHeight,
    });

  useEffect(() => {
    const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));

    const particlePresets_mobile = [
      [0, finity_mobile],
      [1, particles_aktido],
      [2, particles_dizgram],
      [3, particles_tresor],
      [4, particles_tresorevents],
      [5, particles_gogreen],
      [6, particles_mtel],
      [7, particles_eventhallvienna],
      [8, particles_mkautomobile],
      [9, particles_gronbil],
      [10, particles_inzinjering],
      [11, particles_cineart],
      [12, particles_lukafotograf],
      [13, particles_markovarealestate],
      [14, particles_grid],
    ];

    const particlePresets_desktop = [
      [0, finity_desktop],
      [1, aktido_desktop],
      [2, dizgram_desktop],
      [3, tresor_desktop],
      [4, gogreen_desktop],
      [5, mtel_desktop],
      [6, eventhallvienna_desktop],
      [7, tresorevents_desktop],
      [8, mkautomobile_desktop],
      [9, gronbil_desktop],
      [10, inzinjering_desktop],
      [11, cineart_desktop],
      [12, lukafotograf_desktop],
      [13, markovarealestate_desktop],
      [14, particles_grid]
    ];

    const particlePresets = isMobile ? particlePresets_mobile : particlePresets_desktop;

    const handleResize = () => {
      resolutionRef.current.ratio = window.devicePixelRatio || 1;
      resolutionRef.current.nativeWidth = window.innerWidth * (window.devicePixelRatio || 1);
      resolutionRef.current.nativeHeight = window.innerHeight * (window.devicePixelRatio || 1);
      resolutionRef.current.width = window.innerWidth;
      resolutionRef.current.height = window.innerHeight;

      canvasRef.current.width = resolutionRef.current.nativeWidth;
      canvasRef.current.height = resolutionRef.current.nativeHeight;

      webGL.uniform2f(uResolution, resolutionRef.current.nativeWidth, resolutionRef.current.nativeHeight);
      webGL.viewport(0, 0, webGL.drawingBufferWidth, webGL.drawingBufferHeight);
    };
  
    window.addEventListener("resize", handleResize, { passive: false });

    const pointsize = isMobile ? (resolutionRef.current.ratio / 1.8).toFixed(2) : (resolutionRef.current.nativeWidth > 1920 ? "1.2" : "1.0");
    const color = 255;

    const numberOfParticles = isMobile ? 12500 : 22500;
    const positionArray = new Float32Array(numberOfParticles * 3);

    const pointer = { 
      x: 0, 
      y: 0,
      initialize(){
        ["mousemove", "touchstart", "touchmove"].forEach((event, touch) => {
          document.addEventListener(
            event,
            (e) => {
              if (touch) {
                this.x = e.targetTouches[0].clientX * resolutionRef.current.ratio;
                this.y = e.targetTouches[0].clientY * resolutionRef.current.ratio;
              } else {
                this.x = e.clientX * resolutionRef.current.ratio;
                this.y = e.clientY * resolutionRef.current.ratio;
              }
            },
            false,
          );
        });
      }
    };

    pointer.initialize();

    if (particles.current.length === 0) {
      for (let index = 0; index < numberOfParticles; index++) {
        const particleSpeed = Math.round((Math.random() * 500) / 8) + 1;
        particles.current.push(
          new Particle(
            index,
            particleSpeed,
            particlePresets,
            positionArray,
            pointer,
            resolutionRef.current
          )
        );
      }
    }

    canvasRef.current.width = resolutionRef.current.nativeWidth;
    canvasRef.current.height = resolutionRef.current.nativeHeight;

    const webGL = canvasRef.current.getContext('webgl', {
      alpha: true,
      stencil: false,
      antialias: false,
      depth: false,
      powerPreference: "high-performance"
    });

    if (!webGL) {
        console.error('WebGL not supported');
        return;
    }

    const vertexShaderSource = `
        precision highp float;
        attribute vec3 aPosition;
        uniform vec2 uResolution;
        void main() {
            float size = max(${pointsize}, min(1.0, aPosition.z));
            gl_PointSize = size;
            vec2 position = (aPosition.xy / uResolution.xy) * 2.0 - 1.0;
            position.y = -position.y;
            gl_Position = vec4(position, 0.0, 1.0);
        }
    `;

    const vertexShader = webGL.createShader(webGL.VERTEX_SHADER);
    webGL.shaderSource(vertexShader, vertexShaderSource);
    webGL.compileShader(vertexShader);

    const fragmentShaderSource = `
        precision highp float;
        void main() {
            vec2 pc = 2.0 * gl_PointCoord - 1.0;
            gl_FragColor = vec4(${color}, ${color}, ${color}, 1);
        }
    `;

    const fragmentShader = webGL.createShader(webGL.FRAGMENT_SHADER);
    webGL.shaderSource(fragmentShader, fragmentShaderSource);
    webGL.compileShader(fragmentShader);

    const program = webGL.createProgram();
    webGL.attachShader(program, vertexShader);
    webGL.attachShader(program, fragmentShader);
    webGL.linkProgram(program);
    webGL.useProgram(program);

    const uResolution = webGL.getUniformLocation(program, "uResolution");
    webGL.enableVertexAttribArray(uResolution);

    const aPosition = webGL.getAttribLocation(program, "aPosition");
    webGL.enableVertexAttribArray(aPosition);

    webGL.uniform2f(uResolution, resolutionRef.current.nativeWidth, resolutionRef.current.nativeHeight);
    webGL.viewport(0, 0, webGL.drawingBufferWidth, webGL.drawingBufferHeight);

    const webGLBuffer = webGL.createBuffer();

    const runAnimation = () => {
      requestAnimationFrame(runAnimation);

      for (const p of particles.current) 
        p.move(currentParticleIndexRef.current);

      webGL.bindBuffer(webGL.ARRAY_BUFFER, webGLBuffer);
      webGL.vertexAttribPointer(aPosition, 3, webGL.FLOAT, false, 0, 0);
      webGL.bufferData(webGL.ARRAY_BUFFER, positionArray, webGL.DYNAMIC_DRAW);
      webGL.drawArrays(webGL.POINTS, 0, numberOfParticles);
    };

    runAnimation();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    currentParticleIndexRef.current = currentParticleIndex;
  }, [currentParticleIndex]);

  return <canvas ref={canvasRef} style={{
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    backgroundColor: "black"
  }}></canvas>;
};

export default WebGLParticles;